import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"

const ValidateUserInput = (InputComponent) => {
    return React.forwardRef(({
        inputDataType,
        characterLimit,
        mandatory = false,
        minimumLength = null,
        maximumLength = null,
        maxDigitsBeforeDecimal = 6,
        maxDigitsAfterDecimal = 2,
        formErrors,
        setFormErrors,
        position,
        error,
        setError,
        ...props
    }, ref) => {

        const [validationError, setValidationError] = useState(null);
        const dataTypeMap = {
            "alphabets": "text",
            "number": "number",
            "naturalNumber": "number",
            "decimal": "text",
            "mobileNumber": "text",
            "email": "email",
            "url": "text",
            "alphanumeric": "text",
            "nonEmojiText": "text",
            "pincode": "text",
        };

        const passValidationHandler = () => {
            if (inputDataType) {
                if (props?.loadOptions) {
                    if (props?.value || props?.defaultValue) {
                        return {
                            onChange: props?.onChange,
                        };
                    } else {
                        return {
                            onInputChange: (event, actionMeta) => {
                                return handleInputChange(event, actionMeta);
                            },
                            onChange: (event, actionMeta) => {
                                return handleInputChange(event, actionMeta);
                            }
                        }
                    };
                };
                return {
                    onChange: (event) => handleInputChange(event),
                    onBlur: (event) => handleInputChange(event),
                };
            }
        };

        const handleInputChange = (event) => {
            const value = typeof event == "object" ? event?.target?.value ?? event?.value : event;

            if (value && value.trim().length === 0) {
                setValidationError({ type: "invalid", message: "Input cannot be only spaces." });
                if (props?.onChange && !props?.register) {
                    props?.onChange("");
                }
                if (props?.onBlur && !props?.register) {
                    props?.onBlur("");
                }
                return;
            }

            let dataTypeIsValid = validateInputDataType(value);
            if (mandatory && value?.length === 0) {
                setValidationError((error && error?.type === "required" ) ? error : { type: "required", message: "Mandatory field." });
                if (props?.onChange && !props?.register) {
                    props?.onChange(value);
                }
                if (props?.onBlur && !props?.register) {
                    props?.onBlur(value);
                }
                return;
            } else if (!mandatory && value?.length === 0) {
                setValidationError(null);
                return;
            }
            if ((value?.length < minimumLength || value?.length > maximumLength) && dataTypeIsValid) {
                let validationMessage = minimumLength === maximumLength ? `Value should be ${maximumLength} characters long.` : `Value should be ${minimumLength} to ${maximumLength} characters long.`;
                setValidationError({ type: "Length", message: validationMessage });
                if (props?.onChange && !props?.register) {
                    props?.onChange(value);
                }
                if (props?.onBlur && !props?.register) {
                    props?.onBlur(value);
                }
                return;
            }
            if (props?.onChange && !props?.register) {
                props?.onChange(value);
            }
            if (props?.onBlur && !props?.register) {
                props?.onBlur(value);
            }
        };

        const handleKeyPress = (event) => {
            const { charCode } = event;
            const value = event?.target?.value;
            switch(inputDataType) {
                case "mobileNumber":
                    if (!(charCode >= 48 && charCode <= 57) && charCode !== 8) {
                        event.preventDefault();
                    }
                    if (value.length >= maximumLength) {
                        event.preventDefault();
                    }
                    break;
                case "number":
                    if (!(charCode >= 48 && charCode <= 57) && charCode !== 8) {
                        event.preventDefault();
                    }
                    break;
                case "decimal":
                    if (!(charCode >= 48 && charCode <= 57) && charCode !== 8 && charCode !== 46) {
                        event.preventDefault();
                    }
                    // Check if a dot already exists in the input
                    if (value.indexOf('.') !== -1) {
                        if (charCode === 46) {
                            event.preventDefault();
                        }
                    }
                    break;
                case "alphabets":
                    if (!(charCode >= 65 && charCode <= 90) && !(charCode >= 97 && charCode <= 122) && charCode !== 32) {
                        event.preventDefault();
                    }
                    break;
                case "pincode":
                    if (!(charCode >= 48 && charCode <= 57) && charCode !== 8) {
                        event.preventDefault();
                    }
                    break;
                default:
                    break;
            }
        };

        const handlePaste = (event) => {
            let pastedData, sanitizedData;
            switch(inputDataType) {
                case "mobileNumber":
                case "number":
                    pastedData = event.clipboardData.getData("text/plain");
                    sanitizedData = pastedData.replace(/\D/g, "");
                    break;
                case "decimal":
                    pastedData = event.clipboardData.getData("text/plain");
                    sanitizedData = pastedData.replace(/\D/g, "");
                    break;
                case "alphabets":
                    pastedData = event.clipboardData.getData("text/plain");
                    sanitizedData = pastedData.replace(/[^A-Za-z\s]/g, "");
                    event.preventDefault();
                    break;
                default:
                    break;
            }
        };

        const validateInputDataType = (value) => {
            let error = null;
            switch (inputDataType) {
                case "number":
                    if (!/^\d*$/.test(value)) {
                        error = { type: "invalid", message: "Only numbers allowed" };
                    }
                    break;
                case "decimal":
                    if (isNaN(value) || value < 0) {
                        error = { type: "invalid", message: "Only numeric and decimal values are allowed" };
                        break;
                    }
                    if (typeof parseInt(maxDigitsAfterDecimal) != "number") {
                        maxDigitsAfterDecimal = 2;
                    }
                    if (typeof parseInt(maxDigitsBeforeDecimal) != "number") {
                        maxDigitsBeforeDecimal = 6;
                    }
                    if (value.split(".")?.[0]?.length > maxDigitsBeforeDecimal) {
                        error = { type: "invalid", message: `Only ${maxDigitsBeforeDecimal} digit${maxDigitsBeforeDecimal != 1 ? "s" : ""} before decimal allowed` };
                        break;
                    }
                    else if (value.split(".")?.[1]?.length > maxDigitsAfterDecimal && parseInt(value.split(".")?.[1])) {
                        error = { type: "invalid", message: `Only ${maxDigitsAfterDecimal} decimal values allowed` };
                        break;
                    }
                    break;
                case "naturalNumber":
                    if (!/^\d*$/.test(value) || value == 0) {
                        error = { type: "invalid", message: "Value should be numeric and greater than 0" };
                    }
                    break;
                case "email":
                    if (!/^$|^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) {
                        error = { type: "invalid", message: "Invalid Email ID" };
                    }
                    break;
                case "url":
                    if (!/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w.-]*)*\/?$|^$/.test(value)) {
                        error = { type: "invalid", message: "Invalid URL" };
                    }
                    break;
                case "mobileNumber":
                    if (parseInt(value.charAt(0), 10) >= 6 || value?.length === 0) {
                        break;
                    }
                    else {
                        error = { type: "invalid", message: `Enter a vaild 10-digit mobile number` };
                        break;
                    }
                case "alphabets":
                    if (!/^[a-zA-Z\s]*$/.test(value)) {
                        error = { type: "invalid", message: "Only alphabets allowed" };
                    }
                    break;
                case "alphanumeric":
                    if (!/^[a-zA-Z0-9\s!@#$%^&*()_{}\[\]:;<>,.?~`'"\\/\-|+=]*$/.test(value)) {
                        error = { type: "invalid", message: "Invalid! Only alphanumeric characters allowed" };
                    }
                    break;
                case "nonEmojiText":
                    if (/[\p{Emoji_Presentation}]/u.test(value)) {
                        error = { type: "invalid", message: "Emojis are not allowed" };
                    }
                    break;
                case "pincode":
                    if (!/^\d{6}$/.test(value)) {
                        error = { type: "invalid", message: "Invalid Pincode. It should be a 6-digit number." };
                    }
                    break;
                default:
                    break;
            }
            setValidationError(error);
            return !Boolean(error);
        };


        useEffect(() => {
            if (formErrors && setFormErrors) {
                let errors = formErrors;
                errors[position] = Boolean(validationError);
                setFormErrors({...errors});
            }
        }, [validationError])

        return (
            <InputComponent
                mandatory={mandatory}
                characterLimit={characterLimit}
                minimumLength={minimumLength ?? props?.minLength ?? 0}
                maximumLength={maximumLength ?? props?.maxLength ?? 6000}
                error={validationError ? validationError : error}
                handleKeyPress={handleKeyPress}
                inputDataType={dataTypeMap[inputDataType]}
                validationHandler={passValidationHandler()}
                ref={ref}
                {...(props?.loadOptions && { loadOptions: props?.loadOptions })}
                {...props}
            />
        );
    });
};

ValidateUserInput.defaultProps = {
    mandatory: false,
    minimumLength: 0,
    maximumLength: 6000,
};

ValidateUserInput.propTypes = {
    inputDataType: PropTypes.string,
    mandatory: PropTypes.bool,
    minimumLength: PropTypes.number,
    maximumLength: PropTypes.number,
};

export default ValidateUserInput;